let storeUrl = 'https://book-insights-ai.lemonsqueezy.com/buy/'

function url(variatnId: string) {
	return `${storeUrl}${variatnId}`
}

let plans: Array<{
	price: number
	primary?: boolean
	amount: number
	url: string
}> = [
	{
		price: 30,
		primary: true,
		amount: 1,
		url: url('333337d7-bf6a-4145-8406-3841172d456c'),
	},
	{ price: 50, amount: 2, url: url('b9501d8e-56b9-499c-9b70-092ab3c17e68') },
	{ price: 70, amount: 3, url: url('d049e5e4-3180-4886-9cfd-4d3238560ea1') },
	{ price: 100, amount: 5, url: url('00131b9e-d255-462b-9bf0-65d303a08754') },
]

if (process.env.NODE_ENV === 'development') {
	plans = [
		{
			price: 30,
			primary: true,
			amount: 1,
			url: url('f99cd98b-516d-402a-b9ec-70d602e40406'),
		},
		{ price: 50, amount: 2, url: url('17787933-8a4c-4acb-b96d-fa8fd336174f') },
		{ price: 70, amount: 3, url: url('7c7edeaa-4100-4cee-92b2-560ce9f4d500') },
		{ price: 100, amount: 5, url: url('a3004ce6-28e3-4277-beff-3bc489c34587') },
	]
}

function getPlan(price: number) {
	return plans.find(plan => plan.price === price)
}

function planUrlWithUser(urlString: string, userId?: string) {
	let url = new URL(urlString)
	if (userId) {
		url.searchParams.append('checkout[custom][userId]', userId)
	}
	return url.toString()
}

export { plans, getPlan, planUrlWithUser }
