import { Spacer } from '#app/components/spacer'
import { TextWithLineBreaks } from '#app/components/text-with-line-breaks'
import {
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselNext,
	CarouselPrevious,
} from '#app/components/ui/carousel'
import { ButtonLink } from './_utils'

let bookExamples = [
	{
		bookTitle: '100 Mistakes in Software Engineering',
		examples: [
			{
				title: 'Obsessing Over Patterns from Books',
				review: `
The chapter effectively conveys the potential pitfalls of blindly following design patterns without considering the specific needs and context of the project. However, it could benefit from a more balanced perspective by highlighting the value of design patterns when applied judiciously. Examples of successful applications of patterns in appropriate contexts would provide a more well-rounded understanding.

The author's personal anecdote about over-applying patterns is relatable and helps drive the point home. However, additional examples of scenarios where patterns were misapplied or led to overcomplicated solutions would further reinforce the lesson.

The chapter could also explore strategies for evaluating when to apply patterns and when to prioritize simpler solutions. Discussing techniques like YAGNI (You Aren't Gonna Need It) or emphasizing the importance of understanding the trade-offs of each pattern would equip readers to make more informed decisions.

Furthermore, the chapter could touch on the role of experience in recognizing when patterns are beneficial or detrimental. Junior developers might benefit from guidance on when to seek mentorship or advice from more experienced colleagues before applying complex patterns.
`.trim(),
			},
			{
				title: 'Ignoring the Benefits of Mentorship',
				review: `
The chapter effectively illustrates the value of mentorship through a relatable personal anecdote. The author's candid admission of their initial reluctance to seek guidance resonates well and sets the stage for the lesson learned.

To further strengthen the chapter, the author could provide more concrete examples of the specific guidance and insights offered by the mentor, Luke. Rather than describing the mentorship in broad strokes, detailing specific instances where Luke's guidance helped the author overcome challenges or gain new perspectives would make the benefits of mentorship more tangible for readers.

Additionally, the chapter could explore different mentorship models or approaches. For instance, it could discuss the merits of formal mentorship programs versus informal mentoring relationships, or the benefits of peer-to-peer mentoring versus guidance from more senior colleagues.

The chapter could also delve into strategies for finding and establishing effective mentoring relationships. Practical tips on identifying potential mentors, initiating mentorship connections, and maximizing the value of these relationships would be invaluable for readers seeking to replicate the author's positive experience.
`.trim(),
			},
			{
				title: 'Not Keeping it Simple',
				review: `
The chapter effectively conveys the importance of simplicity in software design through a relatable and humorous anecdote. The author's candid admission of their own misstep and the lessons learned resonate well.

To further reinforce the lesson, the chapter could provide additional examples of real-world scenarios where simplicity triumphed over complexity. Citing well-known software products or applications that succeeded due to their simplicity and user-friendliness would drive the point home.

The chapter could also explore techniques or principles for promoting simplicity in software design. Discussing concepts like the KISS (Keep It Simple, Stupid) principle, minimalism, or user-centered design would equip readers with practical strategies for avoiding overcomplicated solutions.

Furthermore, the chapter could delve into the trade-offs between simplicity and advanced features. While simplicity is often desirable, there may be instances where added complexity is justified or necessary. Providing guidance on how to strike the right balance based on user needs and project requirements would be valuable.
`.trim(),
			},
		],
	},
	{
		bookTitle: 'Call of The Wild',
		examples: [
			{
				title: 'Into the Primitive',
				review: `
The opening scene effectively sets the tone and introduces the reader to Buck's world, but more descriptive language could be used to paint a vivid picture of the Santa Clara Valley and Judge Miller's place. For example:

"The sprawling estate grounds could be described in more detail, with sensory descriptions of the lush gardens, the scents of the flowers, and the sounds of the fountains."

The introduction of Buck's heritage and temperament is well-done, but his personality and unique traits could be explored further to make him a more compelling character from the start. For instance:

"Specific examples or anecdotes showcasing Buck's intelligence, loyalty, or any quirks that make him stand out from other dogs could be included."
`.trim(),
			},
			{
				title: 'The Law of Club and Fang',
				review: `
The chapter effectively conveys the harsh realities of the Yukon and the brutal dynamics between the sled dogs. However, the descriptions of the violence and cruelty could be more impactful with vivid sensory details. For example:

"When Curly is attacked, the sounds of the snarls, the ripping of flesh, and the cries of pain could be described more vividly to heighten the intensity and emotional impact of the scene."

The introduction of the key characters like Spitz, Francois, and Perrault is well-done, but their personalities and distinct traits could be further developed to make them more memorable. For instance:

"Francois's unique mannerisms, speech patterns, or physical characteristics could be described in more detail to make him stand out as a distinct character."
`.trim(),
			},
			{
				title: 'The Dominant Primordial Beast',
				review: `
The chapter effectively portrays Buck's transformation and adaptation to the harsh environment. However, the descriptions of his internal struggles and primal instincts could be more detailed and visceral. For example:

"When Buck experiences the resurgence of his primitive instincts, the sensations, emotions, and urges he feels could be described in a more immersive way, allowing the reader to truly empathize with his internal conflict."

The pacing of the chapter is well-handled, but the narrative could benefit from more foreshadowing or hints about Buck's impending clash with Spitz to build anticipation and suspense. For instance:

"Subtle clues or moments of tension between Buck and Spitz could be sprinkled throughout the chapter, gradually ramping up the tension and foreshadowing their inevitable confrontation."
`.trim(),
			},
		],
	},
	{
		bookTitle: 'Legend is Born',
		examples: [
			{
				title: 'The arrival',
				review: `
This chapter could benefit from more vivid descriptions of the island of Namorik and its surroundings. For example, when Helkena arrives on the island, the author could provide more details about the sights, sounds, and smells that she experiences, helping the reader to better visualize and immerse themselves in the setting.

Another potential improvement could be to further develop the interactions and dialogue between Helkena and the other characters she meets, such as Libwiro and Libujen. More detailed and nuanced exchanges could help to better establish their personalities and relationships.

Additionally, the chapter could delve deeper into Helkena's thoughts and emotions as she adjusts to her new surroundings and reflects on her journey from Wotho. Providing more insight into her inner monologue and the conflicting feelings she may be experiencing could add depth and emotional resonance to the narrative.


`.trim(),
			},
			{
				title: 'Lijitwa',

				review: `
This chapter effectively conveys Helkena's initiation into the tattooing and sexual education process under Lijitwa's guidance. However, it could potentially benefit from more detailed descriptions of the tattooing techniques and the specific designs used, as this could heighten the reader's understanding and appreciation of the cultural significance of the practice.

Furthermore, the conversations between Lijitwa and Helkena regarding the "art of fulfillment" and the sexual lessons could be expanded upon to provide a more nuanced exploration of the themes and cultural attitudes surrounding sexuality. This could include additional insights into the societal norms, beliefs, and customs related to these topics.

Finally, the chapter could incorporate more vivid sensory details when describing the physical environments and settings in which these events take place, such as the bathhouse or Lijitwa's dwelling. This could enhance the reader's immersion in the narrative and their understanding of the cultural context.
`.trim(),
			},
			{
				title: "Jipeba's advice",
				review: `
This chapter effectively portrays Helkena's emotional journey following her disappointment with Lojurok's relationship with Liargin. However, it could benefit from a deeper exploration of her inner thoughts and feelings during this period, providing the reader with a more profound understanding of her emotional state and the impact of this setback on her personal growth.

Additionally, the conversations with Jipeba and the advice he offers could be expanded upon to further delve into the cultural wisdom and perspectives he represents. This could include more detailed explanations or examples related to the concepts he discusses, such as the differences between a woman's practical nature and a man's tendency to follow his desires.

Furthermore, the chapter could incorporate more vivid descriptions of the settings and environments in which these conversations take place, allowing the reader to better visualize and immerse themselves in the cultural context.
`.trim(),
			},
		],
	},
]

export function Examples(props: { noShowButton?: boolean }) {
	return (
		<div className="container" id="examples">
			<Spacer size="md" />
			<h2 className="text-center text-h2">Real World Examples</h2>
			<Carousel>
				<Spacer size="4xs" />
				<div className="flex w-full flex-row items-center justify-center gap-2">
					<CarouselPrevious />
					<CarouselNext />
				</div>
				<Spacer size="3xs" />
				<CarouselContent>
					{bookExamples.map(book => (
						<CarouselItem key={book.bookTitle}>
							<p className="text-center text-foreground/60">
								{getExampleDescription(book.bookTitle)}
							</p>
							<Spacer size="2xs" />
							<div className="flex flex-col gap-10">
								{book.examples.map(example => (
									<SingleExample key={example.title} example={example} />
								))}
							</div>
						</CarouselItem>
					))}
				</CarouselContent>
			</Carousel>
			<Spacer size="md" />
			{!props.noShowButton && (
				<div className="flex justify-center">
					<ButtonLink
						prefetch="viewport"
						className="rounded-xl px-16 py-4 text-lg"
						to="/signup"
					>
						Get Reviews Like These For Your Book
					</ButtonLink>
				</div>
			)}
			<Spacer size="md" />
		</div>
	)
}

type Review = (typeof bookExamples)[0]['examples'][number]

function SingleExample(props: { example: Review }) {
	return (
		<div className="bg-foreground/5 px-8 py-6 font-mono">
			<b>
				<span>Chapter: {props.example.title}</span>
			</b>
			<br />
			<br />
			<TextWithLineBreaks>{props.example.review}</TextWithLineBreaks>
		</div>
	)
}

function getExampleDescription(title: string) {
	return `These are real world examples we created for the book \"${title}\"`
}
